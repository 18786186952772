<script>
import DefaultMixin from '../mixin'
import Stats from "components/fenaju/components/financeiro/dashboard/Stats.vue"
import Resumo from "components/fenaju/components/financeiro/dashboard/Graficos.vue"
import ConsoleFinanceiroStatsMensalidade
  from "components/fenaju/components/financeiro/dashboard/EstatisticasMensalidades.vue";
import ConsolePagamentosPendentes from "components/fenaju/components/financeiro/dashboard/PagamentosPendentes.vue";

export default {
  name: 'ConsoleDashboard',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    ConsolePagamentosPendentes,
    ConsoleFinanceiroStatsMensalidade,
    Resumo,
    Stats,
  },
  data() {
    return {
      menuActive: 'suporte'
    }
  },
  computed: {
  },
  beforeMount () {
  },
  watch: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  },
  meta: {
    title: 'Console',
    name: 'Console'
  }
}
</script>

<template>
    <div class="console-dashboard">
      <div class="ev2-body">
        <div class="content-limit">
          <div class="content flex">
            <console-pagamentos-pendentes />
            <console-financeiro-stats-mensalidade />
            <stats />
          </div>
          <div class="content flex">
            <resumo />
            <stats />
          </div>
        </div>
      </div>
    </div>
</template>
